<template>
  <div>
    <AppHeader />
    <!-- CONTENT -->
    <section class="pt-6 pt-md-11 pb-10 pb-md-12 bg-gradient-light">
      <div class="container-lg">
        <div class="row justify-content-center">
          <div class="col-md-10 col-lg-8">

            <!-- Preheading -->
            <h6 class="text-uppercase text-primary text-center mb-5">
              Legal
            </h6>

            <!-- Heading -->
            <h1 class="display-3 text-center mb-4">
              Terms of Service
            </h1>

            <!-- Text -->
            <p class="font-size-lg text-center text-muted mb-0">
              We also may automatically collect certain data when you use the Solutions, such as (1) IP address; (2) domain server; (3) type of device(s) used to access the Solutions; (4) web browser(s) used to access the Solutions; (5) referring webpage or other source through which you accessed the Solutions; (6) geolocation information; and (7) other statistics and information associated with the interaction between your browser or device and the Solutions (collectively “Traffic Data”). Depending on applicable law, some Traffic Data may be Personal Information.
            </p>

            <!-- Divider -->
            <hr class="hr-sm border-warning my-10 my-md-12">

            <!-- Heading -->
            <h2 class="mb-4 font-family-sans-serif">
              Not a Doctor-Patient Relationship
            </h2>

            <!-- Text -->
            <p class="mb-6">
              No licensed medical professional-patient relationship is created when you use the resources and consume the content.
            </p>

            <!-- Heading -->
            <h3 class="font-family-sans-serif mb-4">
              The content is for informational and educational purposes
            </h3>

            <!-- List -->
            <ul class="list-checked list-checked-circle list-checked-primary mb-9 mb-md-10">
              <li>
                Healthcare Provider Content:
                Healthcare Provider and practice Content is intended for general reference purposes only. Healthcare Provider Content may be provided by the Healthcare Provider and/or office staff, and collected from multiple other data sources that may not be confirmed by the Healthcare Provider. Such Content can change frequently and may become out of date, incomplete or inaccurate. Neither the Site nor Lives of SG provides any advice or qualification certification about any particular Healthcare Provider.
                </li>
              <li>
                Procedures/Products/Solutions:
                
                The procedures, products, Solutions and devices discussed and/or marketed through the Solutions are not applicable to all individuals, patients or all clinical situations. Any procedures, products, Solutions or devices represented through the Solutions by advertisers, sponsors, and other participants of the Solutions, either paid or unpaid, are presented for your awareness and do not necessarily imply, and we make no claims as to, safety or appropriateness for any particular individual or prediction of effectiveness, outcome or success.              </li>
              <li>
                Insurance Content:
                
                The insurance and insurance related Content (including, without limitation, insurance coverage Content) (“Insurance Content”) may be intended for general reference purposes and for your convenience only. Such Insurance Content may be based on Personal Information (as defined below) you provide to us as well as Healthcare Provider Content. Insurance Content can change frequently and may become out of date, incomplete or inaccurate. To make this Content as accurate as possible, you agree that you will (a) provide accurate and complete insurance-related Personal Information, and (b) verify and update your insurance-related Personal Information (including, without limitation, verifying such Personal Information obtained by automated means from an insurance card you provide). Although Lives of SG strives to provide you with accurate Insurance Content, we are not responsible for any inaccurate, incomplete or outdated Insurance Content, and you are responsible for complying with subparts (a) and (b) hereof.</li>
            </ul>

          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import AppHeader from './../layout/Header.vue'

export default {
  name: 'Terms',

  components: {
    AppHeader
  },
  data () {
    return {
      msg: 'Welcome to Index!!!',
      isShownModal: false,
      inputError: false,
      checkboxState: false,

      pagination: {
        limit: 20,
        offset: 0,
        total: 60
      }
    }
  },
  mounted () {
  },
  methods: {
    showToast () {
      this.$store.commit('toast/NEW', { type: 'success', message: 'hello' })
    },
    submitModalHandler () {
      // some logic
      this.isShownModal = false
    },
    onBlur () {
      console.log('onBlur!!!')
    },
    onEnter () {
      console.log('onEnter!!!')
    },
    onEsc () {
      console.log('onEsc!!!')
    },
    onClickIcon () {
      console.log('onClickIcon!!!!')
    }
  },

  filters: {
    img: function (value) {
      return require('@/assets/img/' + value)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
