<template>
  <div>
    <AppHeader />
    <section class="pt-6 pt-md-11 pb-8 pb-md-9">
      <div class="container-lg">
        <div class="row justify-content-center">
          <div class="col-md-12 col-xl-10 text-center">

            <!-- Heading -->
            <h1 class="display-2 mb-4">
              We Acknowledge
            </h1>

          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 text-center">
            <!-- Text -->
            <p class="lead text-muted">
              This initiative would not have been possible without the incredible help, advice and strong show of support of these behind-the-scene contributors:
              <br>
              <br> 1. Shalyn Lee
              <br> 2. Cynthia Poa
              <br> 3. Jane Zhao
              <br> 4. Penny Lee
            </p>
          </div>
          
        </div>
      </div>
    </section>
    <!-- TEAM -->
    <section class="pt-10 pt-md-12 bg-gradient-800">
      <div class="container-lg">
          <div class="row align-items-center mb-9">
            <div class="col-12 col-md">
                <!-- Heading -->
                <h2 class="display-4 text-dark mb-0">
                  Our People
                </h2>
                <!-- Text -->
                <p class="text-gray-500 mb-md-0">
                </p>
            </div>
            <div class="col-12 col-md-auto">
                <!-- Nav -->
                <ul class="nav nav-tabs nav-primary nav-overflow mb-0" id="teamTab" role="tablist">
                  <li class="nav-item" role="presentation">
                      <a class="nav-link active" data-toggle="tab" data-filter=".founders" data-target="#team" v-on:click="filter('core')" href="https://goodkit.goodthemes.co/about.html#">
                      Core Team
                      </a>
                  </li>
                  <li class="nav-item" role="presentation">
                      <a class="nav-link" data-toggle="tab" data-filter=".leaders" data-target="#team" v-on:click="filter('leader')" href="https://goodkit.goodthemes.co/about.html#">
                      Team Leaders
                      </a>
                  </li>
                  <li class="nav-item" role="presentation" v-if="!isMobile()">
                      <a class="nav-link" data-toggle="tab" data-filter=".board" data-target="#team" v-on:click="filter('ambassadors')" href="https://goodkit.goodthemes.co/about.html#">
                      Ambassadors and Advocates
                      </a>
                  </li>
                 <li class="nav-item" role="presentation" v-if="isMobile()" style="margin:2px">
                      <a class="nav-link" data-toggle="tab" data-filter=".board" data-target="#team" v-on:click="filter('ambassadors')" href="https://goodkit.goodthemes.co/about.html#">
                      Ambassadors and Advocates
                      </a>
                  </li>
                  <li class="nav-item" role="presentation">
                      <a class="nav-link" data-toggle="tab" data-filter=".investors" data-target="#team" v-on:click="filter('all')" href="https://goodkit.goodthemes.co/about.html#">
                      All
                      </a>
                  </li>
                </ul>
            </div>
          </div>
          <transition name="fade">
            <div class="container" v-if="isCoreTeam">
              <div class="row mt-n5">
                <div class="col-12 col-md-12 founders investors">
                  <!-- Card -->
                  <div class="card card-sm bg-transparent mt-5">
                    <!-- Image -->
                    <img class="card-img-top rounded-top-left rounded-bottom-right" :src="'core-team.jpg' | img" alt="...">
                  </div>
                </div>
                <div class="col-12 col-md-4 founders investors">
                  <!-- Card -->
                  <div class="card card-sm bg-transparent mt-5">
                    <!-- Image -->
                    <img
                      class="card-img-top rounded-top-left rounded-bottom-right profile-picture"
                      :src="'prof-goh.jpg' | img"
                      alt="..."
                    >
                    <!-- Body -->
                    <div class="card-body px-0 text-center">
                        <!-- Heading -->
                        <h4 class="font-family-sans-serif text-dark mb-2">
                          A/Prof Goh Lee Gan
                        </h4>
                        <p class="font-family-sans-serif text-primary mb-4">
                          Senior Family Physician, Senior Consultant, Family Physician Advisor
                        </p>
                        <!-- Text -->
                        <small class="text-muted justify-content-center">
                        Associate Professor Goh’s many interests include health services research, chronic disease management, and medical pedagogy. Over the past few decades, he has been widely and deeply involved in the development of Family Medicine as a discipline, at undergraduate and postgraduate levels, in Singapore as well as regionally. He is currently an Associate Programme Director with the NUHS Family Medicine Residency.
                        </small>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4 founders investors">
                  <!-- Card -->
                  <div class="card card-sm bg-transparent mt-5">
                    <!-- Image -->
                    <img
                      class="card-img-top rounded-top-left rounded-bottom-right profile-picture"
                      :src="'grace-loh.jpg' | img"
                      alt="..."
                    >
                    <!-- Body -->
                    <div class="card-body px-0 text-center">
                        <!-- Heading -->
                        <h4 class="font-family-sans-serif text-dark mb-2">
                          Grace Loh Fournier
                        </h4>
                        <p class="font-family-sans-serif text-primary mb-4">
                          Legal Tech Advocate + Psychotherapist
                        </p>
                        <!-- Text -->
                        <small class="text-muted justify-content-center">
                          Grace Loh is a LegalTech advocate, and currently is Director of Business Development, South East Asia for a UK LegalTech company. She has had 16 years of experience in the Legal & Governance Risk Compliance (GRC) continuing education industry, with experience in operations, international business development, product development, marketing, and sales. Grace holds a Bachelor’s Degree in Psychology and Ecommerce, and a Professional Diploma in Psychotherapy, and she is currently in progress with a Global MBA. A passionate believer in giving back to the community and making a difference, Grace offers pro-bono counselling and psychotherapy, volunteers actively and is keen to capture collaborative partnerships to scale social impact. <br> <br>
                          "Courageous are the people, with compassion and  unarmoured, open hearts".
                        </small>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4 founders investors">
                  <!-- Card -->
                  <div class="card card-sm bg-transparent mt-5">
                    <!-- Image -->
                    <img
                      class="card-img-top rounded-top-left rounded-bottom-right profile-picture"
                      :src="'dr-tan-e-ching.jpg' | img"
                      alt="..."
                    >
                    <!-- Body -->
                    <div class="card-body px-0 text-center">
                        <!-- Heading -->
                        <h4 class="font-family-sans-serif text-dark mb-2">
                          Dr Tan E-Ching
                        </h4>
                        <p class="font-family-sans-serif text-primary mb-4">
                          MBBS (Singapore), GDFP (Dermatology)
                        </p>
                        <!-- Text -->
                        <small class="text-muted justify-content-center">
                          Dr Tan E-Ching is the founding Director of Hallmark Health, a mental wellness and holistic health centre in Singapore. As a Senior Family Physician in practice for the last 20 years, she observed a rising trend of mental health problems like anxiety and depression in all age groups. There was a clearly a pressing need for practitioners who could provide targeted counselling and holistic care and this inspired Dr Tan to start Hallmark Health. Dr Tan is a firm advocate of promoting mental wellness at home and in schools. Dr Tan is deeply passionate about imparting the tools of resilience and emotional intelligence to the younger generation. She has been invited to give talks in schools and run programs on developing Emotional Resilience in youths. Dr Tan is currently actively involved in the Singapore Youth Mental Wellbeing Initiative and conducts workshops for children and youths from disadvantaged backgrounds. Dr Tan hopes to be able to share her experience and expertise especially in today’s climate where people are experiencing exceptionally high levels of anxiety and stress.
                        </small>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4 founders investors">
                  <!-- Card -->
                  <div class="card card-sm bg-transparent mt-5">
                    <!-- Image -->
                    <img
                      class="card-img-top rounded-top-left rounded-bottom-right profile-picture"
                      :src="'paigeshi.png' | img"
                      alt="..."
                    >
                    <!-- Body -->
                    <div class="card-body px-0 text-center">
                        <!-- Heading -->
                        <h4 class="font-family-sans-serif text-dark mb-2">
                          Ms PAIGE SHI 徐佩诗
                        </h4>
                        <p class="font-family-sans-serif text-primary mb-4">
                          Director of Consumer Sales, Asia Pacific, Japan & Korea
                        </p>
                        <!-- Text -->
                        <small class="text-muted justify-content-center">
                        "No one learns through bickering and fighting, but we all listen through communication. Let’s learn to forget about being right, and let’s learn to be just about you and me."
                        </small>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4 founders investors">
                  <!-- Card -->
                  <div class="card card-sm bg-transparent mt-5">
                    <!-- Image -->
                    <img
                      class="card-img-top rounded-top-left rounded-bottom-right profile-picture"
                      :src="'rachelchin.png' | img"
                      alt="..."
                    >
                    <!-- Body -->
                    <div class="card-body px-0 text-center">
                        <!-- Heading -->
                        <h4 class="font-family-sans-serif text-dark mb-2">
                          Dr Rachel Chin
                        </h4>
                        <p class="font-family-sans-serif text-primary mb-4">
                          General Practitioner
                        </p>
                        <!-- Text -->
                        <small class="text-muted justify-content-center">
                          "Nobody should suffer in silence. Anyone should be able to speak up on mental health.”
                        </small>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4 founders investors">
                  <!-- Card -->
                  <div class="card card-sm bg-transparent mt-5">
                    <!-- Image -->
                    <img
                      class="card-img-top rounded-top-left rounded-bottom-right profile-picture"
                      :src="'dr-ang-yong-guan.jpg' | img"
                      alt="..."
                    >
                    <!-- Body -->
                    <div class="card-body px-0 text-center">
                        <!-- Heading -->
                        <h4 class="font-family-sans-serif text-dark mb-2">
                          Dr Ang Yong Guan
                        </h4>
                        <p class="font-family-sans-serif text-primary mb-4">
                          Senior Consultant Psychiatrist
                        </p>
                        <!-- Text -->
                        <small class="text-muted justify-content-center">
                          Practising psychiatrist who, besides treating mental illnesses, helps people to connect within themselves and others and function efficaciously. <br/>" Appreciate, accept, adapt"
                        </small>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4 founders investors">
                  <!-- Card -->
                  <div class="card card-sm bg-transparent mt-5">
                    <!-- Image -->
                    <img
                      class="card-img-top rounded-top-left rounded-bottom-right profile-picture"
                      :src="'Cecilia.png' | img"
                      alt="..."
                    >
                    <!-- Body -->
                    <div class="card-body px-0 text-center">
                        <!-- Heading -->
                        <h4 class="font-family-sans-serif text-dark mb-2">
                          Cecilia Leong-Faulkner
                        </h4>
                        <p class="font-family-sans-serif text-primary mb-4">
                          Founder and Managing Director of the British Theatre Playhouse based in Singapore
                        </p>
                        <!-- Text -->
                        <small class="text-muted justify-content-center">
                          She is a Drama Educator, Creative Producer, Theatre-maker and Performing Arts & Music producer.<br/>"Learn to live, live to learn"
                        </small>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4 founders investors">
                  <!-- Card -->
                  <div class="card card-sm bg-transparent mt-5">
                    <!-- Image -->
                    <img
                      class="card-img-top rounded-top-left rounded-bottom-right profile-picture"
                      :src="'selakovic.png' | img"
                      alt="..."
                    >
                    <!-- Body -->
                    <div class="card-body px-0 text-center">
                        <!-- Heading -->
                        <h4 class="font-family-sans-serif text-dark mb-2">
                          Leah Selakovic
                        </h4>
                        <p class="font-family-sans-serif text-primary mb-4">
                          MA.MSc Media Psychologist
                        </p>
                        <!-- Text -->
                        <small class="text-muted justify-content-center">
                          "A change in behavior or mood may be the early warning signs of a mental health condition and should never be ignored."
                        </small>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4 founders investors">
                  <!-- Card -->
                  <div class="card card-sm bg-transparent mt-5">
                    <!-- Image -->
                    <img
                      class="card-img-top rounded-top-left rounded-bottom-right profile-picture"
                      :src="'valerie-chew.jpg' | img"
                      alt="..."
                    >
                    <!-- Body -->
                    <div class="card-body px-0 text-center">
                        <!-- Heading -->
                        <h4 class="font-family-sans-serif text-dark mb-2">
                          Valerie Chew
                        </h4>
                        <p class="font-family-sans-serif text-primary mb-4">
                          PHD. Principal Investigator
                        </p>
                        <!-- Text -->
                        <small class="text-muted justify-content-center">
                          Translational Immunology Institute (TII), SingHealth-DukeNUS<br>"The struggle you have today is for the strength u will develop TOMORROW"
                        </small>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4 founders investors">
                  <!-- Card -->
                  <div class="card card-sm bg-transparent mt-5">
                    <!-- Image -->
                    <img
                      class="card-img-top rounded-top-left rounded-bottom-right profile-picture"
                      :src="'dr-tan-liat-leng.jpg' | img"
                      alt="..."
                    >
                    <!-- Body -->
                    <div class="card-body px-0 text-center">
                        <!-- Heading -->
                        <h4 class="font-family-sans-serif text-dark mb-2">
                          Dr Tan Liat Leng
                        </h4>
                        <p class="font-family-sans-serif text-primary mb-4">
                          Family physician
                        </p>
                        <!-- Text -->
                        <small class="text-muted justify-content-center">
                          "Let us hold faith to believe that we can help one another to live and reach beyond the dense walls of fear and despair."
                        </small>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4 founders investors">
                  <!-- Card -->
                  <div class="card card-sm bg-transparent mt-5">
                    <!-- Image -->
                    <img
                      class="card-img-top rounded-top-left rounded-bottom-right profile-picture"
                      :src="'pynlim.png' | img"
                      alt="..."
                    >
                    <!-- Body -->
                    <div class="card-body px-0 text-center">
                        <!-- Heading -->
                        <h4 class="font-family-sans-serif text-dark mb-2">
                          Pyn Lim
                        </h4>
                        <p class="font-family-sans-serif text-primary mb-4">
                          CEO & Founder European College of Aesthetic Medicine & Surgery
                        </p>
                        <!-- Text -->
                        <small class="text-muted justify-content-center">
                          "Anxiety and depression is NOT a SICKNESS, it is a SIGNAL that tell us to pursue a meaningful and purposeful life as a tribe!"
                        </small>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4 founders investors">
                  <!-- Card -->
                  <div class="card card-sm bg-transparent mt-5">
                    <!-- Image -->
                    <img
                      class="card-img-top rounded-top-left rounded-bottom-right profile-picture"
                      :src="'liz-chang.jpg' | img"
                      alt="..."
                    >
                    <!-- Body -->
                    <div class="card-body px-0 text-center">
                        <!-- Heading -->
                        <h4 class="font-family-sans-serif text-dark mb-2">
                          Liz Chang
                        </h4>
                        <p class="font-family-sans-serif text-primary mb-4">
                          Medical rep
                        </p>
                        <!-- Text -->
                        <small class="text-muted justify-content-center">
                          "The more we talk about mental issues, the less unnecessarily taboo it becomes ."
                        </small>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4 founders investors">
                  <!-- Card -->
                  <div class="card card-sm bg-transparent mt-5">
                    <!-- Image -->
                    <img
                      class="card-img-top rounded-top-left rounded-bottom-right profile-picture"
                      :src="'dr-gary-ang.jpg' | img"
                      alt="..."
                    >
                    <!-- Body -->
                    <div class="card-body px-0 text-center">
                        <!-- Heading -->
                        <h4 class="font-family-sans-serif text-dark mb-2">
                          Dr Ang Yee Gary
                        </h4>
                        <p class="font-family-sans-serif text-primary mb-4">
                          Medically trained doctor with diverse interests in finance, accounting, contract bridge, startup and investing.
                        </p>
                        <!-- Text -->
                        <small class="text-muted justify-content-center">
                          "Make the world a better place one step at a time. Have you taken the first step?"
                        </small>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4 founders investors">
                  <!-- Card -->
                  <div class="card card-sm bg-transparent mt-5">
                    <!-- Image -->
                    <img
                      class="card-img-top rounded-top-left rounded-bottom-right profile-picture"
                      :src="'pika-sen.jpg' | img"
                      alt="..."
                    >
                    <!-- Body -->
                    <div class="card-body px-0 text-center">
                        <!-- Heading -->
                        <h4 class="font-family-sans-serif text-dark mb-2">
                          Pika Sen
                        </h4>
                        <p class="font-family-sans-serif text-primary mb-4">
                          Consultant Speech & Language Therapist
                        </p>
                        <!-- Text -->
                        <small class="text-muted justify-content-center">
                          "Children who are socially and educationally resilient will be able to tackle any challenge head-on. Bespoke, precision  education is the key"
                        </small>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4 founders investors">
                  <!-- Card -->
                  <div class="card card-sm bg-transparent mt-5">
                    <!-- Image -->
                    <img
                      class="card-img-top rounded-top-left rounded-bottom-right profile-picture"
                      :src="'dr-chu-jiawan.jpg' | img"
                      alt="..."
                    >
                    <!-- Body -->
                    <div class="card-body px-0 text-center">
                        <!-- Heading -->
                        <h4 class="font-family-sans-serif text-dark mb-2">
                          Dr Chu Jiawan
                        </h4>
                        <p class="font-family-sans-serif text-primary mb-4">
                          Beauty Doctor
                        </p>
                        <!-- Text -->
                        <small class="text-muted justify-content-center">
                          Dr Chu Jiawan graduated from National University of Singapore in 2005 with a Bachelor Degree in Medicine and Surgery. She is an experienced family physician and has attained a Post Graduate Diploma in Family Medicine and Certificates of Competence in various aesthetic procedures.<br>
                          With a keen interest in aesthetic procedures and treating dermatological conditions, she has been trusted by family, colleagues and patients alike for her astute sense of beauty and latest fashion trends. She has been awarded the best medical officer award in a government hospital for having received the largest number of compliments from patients. <br>
                          Dr Chu is known to be patient and knowledgeable and has been able to communicate with patients effectively, defining and achieving clinical goals together.
                        </small>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4 founders investors">
                  <!-- Card -->
                  <div class="card card-sm bg-transparent mt-5">
                    <!-- Image -->
                    <img
                      class="card-img-top rounded-top-left rounded-bottom-right profile-picture"
                      :src="'dr-nina.jpg' | img"
                      alt="..."
                    >
                    <!-- Body -->
                    <div class="card-body px-0 text-center">
                        <!-- Heading -->
                        <h4 class="font-family-sans-serif text-dark mb-2">
                          Dr. Nina Andriana
                        </h4>
                        <p class="font-family-sans-serif text-primary mb-4">
                          Physician Aesthetic Medicine (Indonesia)
                        </p>
                        <!-- Text -->
                        <small class="text-muted justify-content-center">
                          Indonesia || Dipl.AAAM., M.Biomed (AAM), M.A.R.S., M.H.
                        </small>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4 founders investors">
                  <!-- Card -->
                  <div class="card card-sm bg-transparent mt-5">
                    <!-- Image -->
                    <img
                      class="card-img-top rounded-top-left rounded-bottom-right profile-picture"
                      :src="'adelene-fong.jpg' | img"
                      alt="..."
                    >
                    <!-- Body -->
                    <div class="card-body px-0 text-center">
                        <!-- Heading -->
                        <h4 class="font-family-sans-serif text-dark mb-2">
                          Adelene Fong
                        </h4>
                        <p class="font-family-sans-serif text-primary mb-4">
                          Director of The Lab Singapore
                        </p>
                        <!-- Text -->
                        <small class="text-muted justify-content-center">
                          Adelene has been working in the banking industry since graduating from university. Adelene has always wanted to give back to society and do more philanthropy especially because she also has a wealth management connection through her work.<br>She loves the outdoors and mountaineering in particular. Growing up, Adelene was a touch rugby player and won the national championships of Singapore. Over the last three years, she has climbed a few mountains around South East Asia, Nepal and Japan and hopes to do more technical alpine climbing in Europe later this year or next year. Adelene is an avid rock climber and is keen to run a marathon one of these days.
                        </small>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4 founders investors">
                  <!-- Card -->
                  <div class="card card-sm bg-transparent mt-5">
                    <!-- Image -->
                    <img
                      class="card-img-top rounded-top-left rounded-bottom-right profile-picture"
                      :src="'dr-daniel-chang.jpg' | img"
                      alt="..."
                    >
                    <!-- Body -->
                    <div class="card-body px-0 text-center">
                        <!-- Heading -->
                        <h4 class="font-family-sans-serif text-dark mb-2">
                          Dr Daniel Chang
                        </h4>
                        <p class="font-family-sans-serif text-primary mb-4">
                          Founder and Enabler<br>Aesthetic Doctor
                        </p>
                        <!-- Text -->
                        <small class="text-muted justify-content-center">
                          A Cosmetic Doctor cum Aesthetic Physician Clinical Trainer who is a firm advocate of mental awareness and wellness and understands that LivesofSG would not be possible without the help of many like-minded friends and peer leaders who rose to the occasion. An avid runner, UFC fan, a Catholic by faith and a father of 3 beautiful princesses. Daniel dreams about retiring on a farmhouse at the foot of a hill, where a freshwater stream flows, trouts fly, and sheep dance across the sky. From the top of the hill, runs an endless field of strawberries and juicy grape-lined vineyards. There, he will be writing articles, and driving to the Gold Coast on weekends to bake in the sun.
                        </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
          <transition name="fade">
            <div class="container mt-5" v-if="isTeamLead">
              <div class="row mt-n5">
                <div class="col-12 col-md-12 founders investors">
                  <!-- Card -->
                  <div class="card card-sm bg-transparent mt-5">
                    <!-- Image -->
                    <img class="card-img-top rounded-top-left rounded-bottom-right" :src="'team-leaders.jpg' | img" alt="...">
                  </div>
                </div>
              </div>
            </div>
          </transition>
          <transition name="fade">
            <div class="container mt-5" v-if="isAmbassador">
              <div class="row mt-n5">
                <div class="col-12 col-md-12 founders investors">
                  <!-- Card -->
                  <div class="card card-sm bg-transparent mt-5">
                    <!-- Image -->
                    <img class="card-img-top rounded-top-left rounded-bottom-right" :src="'ambassadors-advocates.jpg' | img" alt="...">
                  </div>
                </div>
                <div class="col-12 col-md-12 founders investors">
                  <!-- Card -->
                  <div class="card card-sm bg-transparent mt-5">
                    <!-- Image -->
                    <img class="card-img-top rounded-top-left rounded-bottom-right" :src="'ambassadors-2.jpg' | img" alt="...">
                  </div>
                </div>
              </div>
            </div>
          </transition>
      </div>
    </section>
  </div>
</template>

<script>
import AppHeader from './../layout/Header.vue'

export default {
  name: 'People',

  components: {
    AppHeader
  },
  data () {
    return {
      msg: 'Welcome to Index!!!',
      isShownModal: false,
      inputError: false,
      checkboxState: false,
      isCoreTeam: true,
      isTeamLead: false,
      isAmbassador: false,

      pagination: {
        limit: 20,
        offset: 0,
        total: 60
      },
      currentTag: 'core'
    }
  },
  mounted () {

  },
  methods: {
    showToast () {
      this.$store.commit('toast/NEW', { type: 'success', message: 'hello' })
    },
    submitModalHandler () {
      // some logic
      this.isShownModal = false
    },
    onBlur () {
      console.log('onBlur!!!')
    },
    onEnter () {
      console.log('onEnter!!!')
    },
    onEsc () {
      console.log('onEsc!!!')
    },
    onClickIcon () {
      console.log('onClickIcon!!!!')
    },
    filter: function (tag) {
      this.currentTag = tag
      switch (this.currentTag) {
        case 'core':
          this.isCoreTeam = true
          this.isTeamLead = false
          this.isAmbassador = false
          break
        case 'leader':
          this.isCoreTeam = false
          this.isTeamLead = true
          this.isAmbassador = false
          break
        case 'ambassadors':
          this.isCoreTeam = false
          this.isTeamLead = false
          this.isAmbassador = true
          break
        case 'all':
          this.isCoreTeam = true
          this.isTeamLead = true
          this.isAmbassador = true
          break
        default:
          break
      }
    },
    isMobile () {
      console.log('window.innerWidth', window.innerWidth)
      return window.innerWidth < 700
    }
  },

  filters: {
    img: function (value) {
      return require('@/assets/img/' + value)
    }
  }
}
</script>

<style lang="scss" scoped>
.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.profile-picture {
  width: 224px;
  height: 280px;
  align-self: center;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
