<template>
    <nav class="navbar navbar-expand-lg navbar-light mt-n4">
      <div class="container-lg">

        <!-- Brand -->
        <router-link class="navbar-brand d-lg-none text-primary" to="/">Lives of SG</router-link>

        <!-- Toggler -->
        <button class="navbar-toggler text-primary" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon text-primary"></span>
        </button>

        <!-- Collapse -->
        <div class="collapse navbar-collapse text-dark" id="navbarSupportedContent">

          <!-- Navigation -->
          <ul class="navbar-nav justify-content-end w-100">
            <li class="nav-item dropdown">
              <router-link class="nav-link text-dark" id="landingsDropdown" to="/about" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                About
              </router-link>
            </li>
            <li class="nav-item dropdown">
              <router-link class="nav-link text-dark" id="pagesDropdown" to="/people" role="button">
                People
              </router-link>
            </li>
          </ul>

          <!-- Brand -->
          <a class="navbar-brand d-none d-lg-block px-lg-6 text-primary" href="/">Lives of SG</a>

          <!-- Navigation -->
          <ul class="navbar-nav justify-content-start w-100">
            <li class="nav-item dropdown">
              <router-link class="nav-link text-dark" id="pagesDropdown" to="/support" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Support
              </router-link>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link text-dark" id="pagesDropdown" href="/contact" role="button">
                Contact
              </a>
            </li>
          </ul>

        </div>

      </div>
    </nav>
</template>

<script>
// import { AuthService } from '../services/auth.service'
// import UiHeaderDropdownMenu from '@/components/UiHeaderDropdownMenu.vue'
// import UiToastList from '@/components/UiToastList'
import { CORPORATE_LOGIN_URL } from '../.env'

export default {
  name: 'Header',
  components: {
    // UiToastList,
    // UiHeaderDropdownMenu
  },
  data: () => {
    return {
      coporateURL: CORPORATE_LOGIN_URL
    }
  },
  methods: {
    // async logout () {
    //   await AuthService.makeLogout()
    // }
  },
  filters: {
    img: function (value) {
      return require('@/assets/img/' + value)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
