// profile
// import profilePage from '../pages/profile/ProfilePage.vue'
// import profilePostsPage from '../pages/profile/ProfilePostsPage.vue'

// single pages
import homePage from '../pages/Home.vue'
import aboutUs from '../pages/AboutUs.vue'
import people from '../pages/People.vue'
import privacy from '../pages/Privacy.vue'
import terms from '../pages/Terms.vue'
import support from '../pages/Support.vue'
import faq from '../pages/FAQ.vue'

// import newsPage from '../pages/news/NewsPage.vue'
// import loginPage from '../pages/Login.vue'
import notFoundPage from '../pages/NotFound.vue'

// import { routePropResolver } from './util'
import { DOMAIN_TITLE } from '../.env'

export const routes = [
  {
    path: '/',
    name: 'index',
    component: homePage,
    meta: { title: `${DOMAIN_TITLE} | Home` }
  },
  // {
  //   path: '/',
  //   name: 'index',
  //   component: homePage,
  //   meta: { title: `${DOMAIN_TITLE} | Home` }
  // },
  {
    path: '/about',
    name: 'index',
    component: aboutUs,
    meta: { title: `${DOMAIN_TITLE} | About Us` }
  },
  {
    path: '/people',
    name: 'index',
    component: people,
    meta: { title: `${DOMAIN_TITLE} | People` }
  },
  {
    path: '/privacy',
    name: 'index',
    component: privacy,
    meta: { title: `${DOMAIN_TITLE} | Privacy` }
  },
  {
    path: '/terms',
    name: 'index',
    component: terms,
    meta: { title: `${DOMAIN_TITLE} | Terms` }
  },
  {
    path: '/support',
    name: 'index',
    component: support,
    meta: { title: `${DOMAIN_TITLE} | Support` }
  },
  {
    path: '/contact',
    name: 'index',
    component: faq,
    meta: { title: `${DOMAIN_TITLE} | Contact` }
  },
  // {
  //   path: '/news',
  //   name: 'news',
  //   component: newsPage,
  //   meta: { title: `${DOMAIN_TITLE} | news` },
  //   props: routePropResolver
  // },
  // {
  //   path: '/profile',
  //   component: profilePage,
  //   meta: { isAuth: true, title: `${DOMAIN_TITLE} | profile` },
  //   children: [
  //     {
  //       path: '',
  //       name: 'profile',
  //       component: profilePostsPage
  //     }
  //   ]
  // },
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: loginPage,
  //   meta: { title: `${DOMAIN_TITLE} | login` }
  // },
  {
    path: '*',
    component: notFoundPage,
    meta: { title: `${DOMAIN_TITLE} | not found` }
  }
]
