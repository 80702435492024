<template>
  <div id="app">
    <router-view></router-view>
    <AppFooter></AppFooter>
  </div>
</template>

<script>
// import AppHeader from './Header.vue'
import AppFooter from './Footer.vue'

export default {
  name: 'AppLayout',
  components: {
    // AppHeader,
    AppFooter
  }
}
</script>s
