<template>
  <div>
    <AppHeader />
    <!-- TEAM -->
    <section class="pt-10 pt-md-12 pb-9 pb-md-11 bg-gradient-light">
    <div class="container-lg">
        <div class="row align-items-center mb-9">
          <div class="col-12 col-md">
              <!-- Heading -->
              <h2 class="display-4 text-dark mb-0">
                Get Support
              </h2>
              <!-- Text -->
              <p class="text-gray-500 mb-md-0">
              </p>
          </div>
          <div class="col-12 col-md-auto">
              <!-- Nav -->
              <ul class="nav nav-tabs nav-primary nav-overflow mb-0" id="teamTab" role="tablist">
                <li class="nav-item" role="presentation">
                    <a class="nav-link active" data-toggle="tab" data-filter=".founders" data-target="#team" v-on:click="filter('care')" href="https://goodkit.goodthemes.co/about.html#">
                    Care Team
                    </a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" data-toggle="tab" data-filter=".board" data-target="#team" v-on:click="filter('doctor')" href="https://goodkit.goodthemes.co/about.html#">
                    Panel Doctors
                    </a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" data-toggle="tab" data-filter=".investors" data-target="#team" v-on:click="filter('all')" href="https://goodkit.goodthemes.co/about.html#">
                    All
                    </a>
                </li>
              </ul>
          </div>
        </div>
          <div class="row mt-n5 active" id="team" data-isotope="{&quot;layoutMode&quot;: &quot;fitRows&quot;, &quot;imagesLoaded&quot;: true}" style="position: relative; height: 807.391px;">
            <transition name="bounce">
              <div class="col-12 col-md-12 founders" style="position: absolute; left: 0px; top: 0px;" v-if="isCare">
                  <!-- Card -->
                  <div class="card card-sm bg-transparent mt-5">
                    <!-- Image -->
                    <img class="card-img-top rounded-top-left rounded-bottom-right" :src="'team-leaders.jpg' | img" alt="...">
                  </div>
              </div>
            </transition>
            <div class="col-6 col-md-3 board investors" style="position: absolute; left: 0px; top: 0px;" v-if="isDoctor">
                <!-- Card -->
                <div class="card card-sm bg-transparent mt-5">
                  <!-- Image -->
                  <img class="card-img-top rounded-top-left rounded-bottom-right profile-picture" :src="'dr-ang-yong-guan.jpg' | img" alt="...">
                  <!-- Body -->
                  <div class="card-body px-0 text-center">
                      <!-- Heading -->
                      <h5 class="font-family-sans-serif text-dark mb-0">
                        Dr Ang Yong Guan
                      </h5>
                      <!-- Text -->
                      <small class="text-muted">
                      Central
                      </small>
                      <a href="https://paragonmedical.com.sg/practitioners_page/ang-yong-guan-psychiatry"></a>
                  </div>
                </div>
            </div>
            <div class="col-6 col-md-3 board investors" style="position: absolute; left: 285px; top: 0px;" v-if="isDoctor">
                <!-- Card -->
                <div class="card card-sm bg-transparent mt-5">
                  <!-- Image -->
                  <img class="card-img-top rounded-top-left rounded-bottom-right profile-picture" :src="'dr-tan-e-ching.jpg' | img" alt="...">
                  <!-- Body -->
                  <div class="card-body px-0 text-center">
                      <!-- Heading -->
                      <h5 class="font-family-sans-serif text-dark mb-0">
                        Dr Tan E Ching
                      </h5>
                      <!-- Text -->
                      <small class="text-muted">
                      East
                      </small>
                      <a href="https://hallmarkhealth.com.sg/"></a>
                  </div>
                </div>
            </div>
            <div class="col-6 col-md-3 board investors" style="position: absolute; left: 570px; top: 0px;" v-if="isDoctor">
                <!-- Card -->
                <div class="card card-sm bg-transparent mt-5">
                  <!-- Image -->
                  <img class="card-img-top rounded-top-left rounded-bottom-right profile-picture" :src="'dr-tan-liat-leng.jpg' | img" alt="...">
                  <!-- Body -->
                  <div class="card-body px-0 text-center">
                      <!-- Heading -->
                      <h5 class="font-family-sans-serif text-dark mb-0">
                        Dr Tan Liat Leng
                      </h5>
                      <!-- Text -->
                      <small class="text-muted">
                      South
                      </small>
                  </div>
                </div>
            </div>
            <div class="col-6 col-md-3 board investors" style="position: absolute; left: 855px; top: 0px;" v-if="isDoctor">
                <!-- Card -->
                <div class="card card-sm bg-transparent mt-5">
                  <!-- Image -->
                  <img class="card-img-top rounded-top-left rounded-bottom-right profile-picture" :src="'prof-goh-panel.jpg' | img" alt="...">
                  <!-- Body -->
                  <div class="card-body px-0 text-center">
                      <!-- Heading -->
                      <h5 class="font-family-sans-serif text-dark mb-0">
                        A/Prof Goh Lee Gan
                      </h5>
                      <!-- Text -->
                      <small class="text-muted">
                      West
                      </small>
                  </div>
                </div>
            </div>
          </div>
    </div>
  </section>
  <!-- Connect -->
  <section class="pt-9 pt-md-11 pb-9 pb-md-11 bg-gradient-light">
    <div class="container-lg">
        <div class="row justify-content-center">
          <div class="row justify-content-center" style="width: 100%;">
              <div class="col-12 col-lg-10 text-center">
                <!-- Heading -->
                <h2 class="display-4 mb-4">
                    Our Last Dance Playlist
                </h2>
                <p class="col-12 col-lg-12 text-center mb-8">
                </p>
              </div>
          </div>
        </div>
        <div class="row mt-n6" v-if="!isMobile()">
          <div class="col-md-12">
              <!-- Card -->
              <div class="card card-sm rounded-top-left rounded-bottom-right lift lift-lg mt-6 align-middle">
                <!-- Body -->
                <div class="card-body" style="align-self: center;">
                    <iframe width="280" height="200" src="https://www.youtube.com/embed/2M_BhogruZ0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>
          </div>
        </div>
    </div>
  </section>

  <section class="pt-9 pt-md-11 pb-9 pb-md-11 bg-gradient-light">
    <div class="container-lg">
        <div class="row justify-content-center">
          <div class="row justify-content-center" style="width: 100%;">
              <div class="col-12 col-lg-10 text-center">
                <!-- Heading -->
                <h2 class="display-4 mb-4">
                    Video Tips
                </h2>
                <p class="col-12 col-lg-12 text-center mb-8">
                </p>
              </div>
          </div>
        </div>
        <div class="row mt-n6" v-if="!isMobile()">
          <div class="col-md-4">
              <!-- Card -->
              <div class="card card-sm rounded-top-left rounded-bottom-right lift lift-lg mt-6">
                <!-- Body -->
                <div class="card-body">
                    <iframe width="280" height="200" src="https://www.youtube.com/embed/2M_BhogruZ0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>
          </div>
          <div class="col-md-4">
              <!-- Card -->
              <div class="card card-sm rounded-top-left rounded-bottom-right lift lift-lg mt-6">
                <!-- Body -->
                <div class="card-body">
                    <iframe width="280" height="200" src="https://www.youtube.com/embed/9Yn-wFnEwfc" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>
          </div>
          <div class="col-md-4">
              <!-- Card -->
              <div class="card card-sm rounded-top-left rounded-bottom-right lift lift-lg mt-6">
                <!-- Body -->
                <div class="card-body">
                    <iframe width="280" height="200" src="https://www.youtube.com/embed/2mflSd6lwFg" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>
          </div>
        </div>
        <div class="row mt-2" v-if="!isMobile()">
          <div class="col-md-4">
              <!-- Card -->
              <div class="card card-sm rounded-top-left rounded-bottom-right lift lift-lg mt-6">
                <!-- Body -->
                <div class="card-body">
                    <iframe width="280" height="200" src="https://www.youtube.com/embed/wGz6tuujd2k" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>
          </div>
          <div class="col-md-4">
              <!-- Card -->
              <div class="card card-sm rounded-top-left rounded-bottom-right lift lift-lg mt-6">
                <!-- Body -->
                <div class="card-body">
                    <iframe width="280" height="200" src="https://www.youtube.com/embed/Y-Y0kN_m6Vk" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>
          </div>
          <div class="col-md-4">
              <!-- Card -->
              <div class="card card-sm rounded-top-left rounded-bottom-right lift lift-lg mt-6">
                <!-- Body -->
                <div class="card-body">
                    <iframe width="280" height="200" src="https://www.youtube.com/embed/K7l1E8t2NWc" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>
          </div>
        </div>
        <carousel :perPage="1" :autoplay="true" :loop="true" v-if="isMobile()">
        <slide>

                    <iframe width="343" height="200" src="https://www.youtube.com/embed/2M_BhogruZ0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

        </slide>
        <slide>

                    <iframe width="343" height="200" src="https://www.youtube.com/embed/9Yn-wFnEwfc" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

        </slide>
        <slide>

                  <iframe width="343" height="200" src="https://www.youtube.com/embed/2mflSd6lwFg" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

        </slide>
        <slide>

                    <iframe width="343" height="200" src="https://www.youtube.com/embed/wGz6tuujd2k" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

        </slide>
        <slide>

                    <iframe width="343" height="200" src="https://www.youtube.com/embed/Y-Y0kN_m6Vk" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

        </slide>
        <slide>
                    <iframe width="343" height="200" src="https://www.youtube.com/embed/K7l1E8t2NWc" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </slide>
      </carousel>
    </div>
  </section>
  <!-- Psychologist List -->
  <section class="pt-6 pt-md-11 pb-10 pb-md-12 bg-gradient-light">
    <div class="container-lg">
        <div class="row justify-content-center">
          <div class="col-md-6 col-lg-5">
              <!-- Heading -->
              <h2 class="display-4 text-center mb-4">
                Psychotherapists Team
              </h2>
              <!-- Divider -->
              <hr class="hr-sm border-warning my-5 my-md-6">
              <!-- List -->
              <ul class="list-checked list-checked-circle list-checked-primary">
                <li>
                    Shalini Mohan
                </li>
                <li>
                    Ang Ee Ling Lena
                </li>
                <li>
                    Vishnu
                </li>
                <li>
                    Irene Monica
                </li>
                <li>
                    Foong Yee Mei
                </li>
                <li>
                    Cathryn Choy
                </li>
                <li>
                    Julie Barnicott
                </li>
                <li>
                    Yasmin Merchant
                </li>
                <li>
                    Shanti
                </li>
                <li>
                    Eckshar
                </li>
                <li>
                    Jumh
                </li>
                <li>
                    Anupama
                </li>
                <li>
                    Namita
                </li>
                <li>
                    Farhaini Zohri
                </li>
                <li>
                    Ken
                </li>
                <li>
                    Benjamin
                </li>
                <li>
                    Nicole
                </li>
                <li>
                    Divya Sharma
                </li>
                <li>
                    Ng Shi Lin
                </li>
                <li>
                    Sainiran
                </li>
                <li>
                    Davelle
                </li>
                <li>
                    Prionka
                </li>
                <li>
                    Stephanie Psarski
                </li>
                <li>
                    Saratha
                </li>
              </ul>
          </div>
        </div>
    </div>
  </section>
  <!-- Community Support -->
  <section class="py-10 py-md-12 pb-9 pb-md-11 bg-gradient-light">
    <div class="container-lg">
        <div class="row justify-content-center">
          <div class="col-md-10 col-lg-8 text-center">
              <!-- Divider -->
              <hr class="hr-sm border-warning mb-10 mb-md-12">
              <!-- Heading -->
              <h2 class="display-4 mb-4">
                Other Support
              </h2>
          </div>
        </div>
        <div class="row mt-n6">
          <div class="col-md-4">
              <!-- Card -->
              <div class="card card-sm shadow-none mt-6">
                <!-- Image -->
                <img class="card-img-top mw-25 mw-md-50 mx-auto" :src="'sos.png'| img" alt="...">
                <!-- Body -->
                <div class="card-body text-center">
                    <!-- Heading -->
                    <h4 class="mb-2">
                      Samaritans of Singapore (SOS)
                    </h4>
                    <!-- Text -->
                    <p class="font-size-sm text-gray-600 mb-3">
                      Daily, 24 Hours
                    </p>
                    <!-- Link -->
                    <a class="h4 font-family-sans-serif" href="tel:1800 221 4444">
                    1800 221 4444<i class="fe fe-chevron-right"></i>
                    </a>
                </div>
              </div>
          </div>
          <div class="col-md-4">
              <!-- Card -->
              <div class="card card-sm shadow-none mt-6">
                <!-- Image -->
                <img class="card-img-top mw-25 mw-md-50 mx-auto" :src="'imh.png'| img" alt="...">
                <!-- Body -->
                <div class="card-body text-center">
                    <!-- Heading -->
                    <h4 class="mb-2">
                      Institute of Mental Health (IMH)
                    </h4>
                    <!-- Text -->
                    <p class="font-size-sm text-gray-600 mb-3">
                    </p>
                    <!-- Link -->
                    <a class="h4 font-family-sans-serif" href="tel:+65 6389-2222">
                    +65 6389-2222<i class="fe fe-chevron-right"></i>
                    </a>
                </div>
              </div>
          </div>
          <div class="col-md-4">
              <!-- Card -->
              <div class="card card-sm shadow-none mt-6">
                <!-- Image -->
                <img class="card-img-top mw-25 mw-md-50 mx-auto" :src="'samh.png'| img" alt="...">
                <!-- Body -->
                <div class="card-body text-center">
                    <!-- Heading -->
                    <h4 class="mb-2">
                      Singapore Association of Mental Health (SAMH)
                    </h4>
                    <!-- Text -->
                    <p class="font-size-sm text-gray-600 mb-3">
                    </p>
                    <!-- Link -->
                    <a class="h4 font-family-sans-serif" href="tel:1800-283-7019">
                    1800 283 7019 <i class="fe fe-chevron-right"></i>
                    </a>
                </div>
              </div>
          </div>
        </div>
        <div class="row mt-n6">
          <div class="col-md-4">
              <!-- Card -->
              <div class="card card-sm shadow-none mt-6">
                <!-- Image -->
                <img class="card-img-top mw-25 mw-md-50 mx-auto" :src="'saf.png'| img" alt="...">
                <!-- Body -->
                <div class="card-body text-center">
                    <!-- Heading -->
                    <h4 class="mb-2">
                      Singapore Armed Forces (SAF) Hotline
                    </h4>
                    <!-- Text -->
                    <p class="font-size-sm text-gray-600 mb-3">
                      For SAF Personnel
                    </p>
                    <!-- Link -->
                    <a class="h4 font-family-sans-serif" href="tel:1800 278 0022">
                    1800 278 0022<i class="fe fe-chevron-right"></i>
                    </a>
                </div>
              </div>
          </div>
          <div class="col-md-4">
              <!-- Card -->
              <div class="card card-sm shadow-none mt-6">
                <!-- Image -->
                <img class="card-img-top mw-25 mw-md-50 mx-auto mt-2" src="https://static.wixstatic.com/media/265d86_351e1edf992e44c1b8a740470b805ad7~mv2.png/v1/fill/w_400,h_88,al_c,q_85,usm_0.66_1.00_0.01/265d86_351e1edf992e44c1b8a740470b805ad7~mv2.webp" alt="...">
                <!-- Body -->
                <div class="card-body text-center">
                    <!-- Heading -->
                    <h4 class="mb-2">
                      Mindline
                    </h4>
                    <!-- Text -->
                    <p class="font-size-sm text-gray-600 mb-3">
                      Online Therapy Chatbot
                    </p>
                    <!-- Link -->
                    <a class="h4 font-family-sans-serif" href="https://www.mindline.sg">
                    https://www.mindline.sg<i class="fe fe-chevron-right"></i>
                    </a>
                </div>
              </div>
          </div>
          <div class="col-md-4">
              <!-- Card -->
              <div class="card card-sm shadow-none mt-6">
                <!-- Image -->
                <img class="card-img-top mw-25 mw-md-50 mx-auto mt-2" src="https://user-images.strikinglycdn.com/res/hrscywv4p/image/upload/c_limit,fl_lossy,h_300,w_300,f_auto,q_auto/250253/zdclkvjwuyzwklleian3.png" alt="...">
                <!-- Body -->
                <div class="card-body text-center">
                    <!-- Heading -->
                    <h4 class="mb-2">
                      WYSA
                    </h4>
                    <!-- Text -->
                    <p class="font-size-sm text-gray-600 mb-3">
                      Online Therapy Chatbot
                    </p>
                    <!-- Link -->
                    <a class="h4 font-family-sans-serif" href="https://www.wysa.io/">
                    https://www.wysa.io/<i class="fe fe-chevron-right"></i>
                    </a>
                </div>
              </div>
          </div>
        </div>
    </div>
  </section>
  </div>
</template>

<script>
import AppHeader from './../layout/Header.vue'
import backgroundUrl from '../assets/img/banner.jpg'
import { Carousel, Slide } from 'vue-carousel'

export default {
  name: 'Support',

  components: {
    AppHeader,
    Carousel,
    Slide
  },
  computed: {
    filteredItems: function () {
      var filter = this.currentTag
      return this.items.filter(function (item) {
        return item.tags.indexOf(filter) !== -1
      })
    }
  },
  data () {
    return {
      msg: 'Welcome to Index!!!',
      backgroundUrl,
      isShownModal: false,
      inputError: false,
      checkboxState: false,
      isCare: true,
      isDoctor: false,
      pagination: {
        limit: 20,
        offset: 0,
        total: 60
      },
      items: [
        { id: 1, tags: ['all', 'care'], name: 'Ferry B', image: 'team-leaders.jpg' },
        { id: 2, tags: ['all', 'doctor'], name: 'Eve Zhang', image: 'dr-ang-yong-guan.jpg' },
        { id: 3, tags: ['all', 'doctor'], name: 'Leah Selakovic', image: 'dr-ang-yong-guan.jpg' },
        { id: 4, tags: ['all', 'doctor'], name: 'Gloria Soo', image: 'dr-ang-yong-guan.jpg' },
        { id: 5, tags: ['all', 'doctor'], name: 'Eleanor Wong', image: 'dr-ang-yong-guan.jpg' }
        // { id: 6, tags: ['all', 'tag2'], name: 'Desiree' },
        // { id: 7, tags: ['all', 'tag1'], name: 'Dr Ang Yong Guan' },
        // { id: 8, tags: ['all', 'tag3'], name: 'Dr Tan E Ching' },
        // { id: 9, tags: ['all', 'tag3'], name: 'Dr Tan Liat Leng' },
        // { id: 10, tags: ['all', 'tag3'], name: 'Prof Goh Lee Gan' }
      ],
      currentTag: 'care'
    }
  },
  mounted () {
    var grid = document.getElementById('team')
    setTimeout(() => {
      const isMobile = window.innerWidth < 700
      if (isMobile) {
        grid.style.height = '266.5px'
      } else {
        grid.style.height = '807.391px'
      }
    }, 0)
    // var grid = document.querySelector('#team')
    // var iso = new Isotope(grid, {
    //   // options...
    //   itemSelector: '.grid-item',
    //   masonry: {
    //     columnWidth: 200
    //   }
    // })
    // console.log(iso)
  },
  methods: {
    showToast () {
      this.$store.commit('toast/NEW', { type: 'success', message: 'hello' })
    },
    submitModalHandler () {
      // some logic
      this.isShownModal = false
    },
    onBlur () {
      console.log('onBlur!!!')
    },
    onEnter () {
      console.log('onEnter!!!')
    },
    onEsc () {
      console.log('onEsc!!!')
    },
    onClickIcon () {
      console.log('onClickIcon!!!!')
    },
    filter: function (tag) {
      this.currentTag = tag
      var grid = document.getElementById('team')
      const isMobile = window.innerWidth < 700
      switch (this.currentTag) {
        case 'care':
          this.isCare = true
          this.isDoctor = false
          if (isMobile) {
            grid.style.height = '266.5px'
          } else {
            grid.style.height = '807.391px'
          }

          break
        case 'doctor':
          this.isCare = false
          this.isDoctor = true
          if (isMobile) {
            grid.style.height = '621.25px'
          } else {
            grid.style.height = '417px'
          }

          setTimeout(() => {
            var boardArray = document.getElementsByClassName('board')
            console.log('boardArray', boardArray)
            var counter = 0
            Array.from(boardArray).forEach((el) => {
              el.style.top = '0px'
              if (isMobile) {
                switch (counter) {
                  case 1:
                    el.style.left = '50%'
                    break
                  case 2:
                    el.style.left = '0px'
                    el.style.top = '310.625px'
                    break
                  case 3:
                    el.style.left = '50%'
                    el.style.top = '310.625px'
                    break
                }
              }
              counter++
            })
          }, 0)
          break
        case 'all':
          this.isCare = true
          this.isDoctor = true

          if (isMobile) {
            grid.style.height = '887.75px'
          } else {
            grid.style.height = '1224.39px'
          }
          
          setTimeout(() => {
            var boardArray = document.getElementsByClassName('board')
            console.log('boardArray', boardArray)
            let counter = 0
            Array.from(boardArray).forEach((el) => {
              if (isMobile) {
                el.style.top = '266.5px'
                switch (counter) {
                  case 1:
                    el.style.left = '50%'
                    break
                  case 2:
                    el.style.left = '0px'
                    el.style.top = '577.125px'
                    break
                  case 3:
                    el.style.left = '50%'
                    el.style.top = '577.125px'
                    break
                }
                counter++
              } else {
                el.style.top = '807.391px'
              }
            })
          }, 0)
          break
        default:
          grid.style.height = '807.391px'
          break
      }
    },
    isMobile () {
      console.log('window.innerWidth', window.innerWidth)
      return window.innerWidth < 700
      // if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      //   return true
      // } else {
      //   return false
      // }
    }
  },

  filters: {
    img: function (value) {
      return require('@/assets/img/' + value)
    }
  }
}
</script>

<style lang="scss" scoped>
.list-complete {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
}
.list-complete-item {
  transition: transform 1s;
  flex: 1 1 25%;
  height: 200px;
  padding: 10px;
/*   display: inline-block;
  margin-right: 10px; */
}
.list-complete-img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.list-complete-enter, .list-complete-leave-to
/* .list-complete-leave-active for <2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.list-complete-leave-active {
  position: absolute;
}
.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

@media only screen and (min-width: 800px) {
  .profile-picture {
    width: 224px;
    height: 280px;
    align-self: center;
  }
}

</style>
