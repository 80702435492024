<template>
  <div>
    <AppHeader />
    <!-- Vision, Mission, Objective -->
    <section class="pt-9 pt-md-11 pb-9 pb-md-11 bg-gradient-light">
      <div class="container-lg">
        <div class="row justify-content-center">
          <div class="col-md-10 col-lg-8 text-center">

            <!-- Heading -->
            <h2 class="display-3 mb-6">
              About Us
            </h2>
          </div>
        </div>
        <div class="row mt-n6">
          <div class="col-md-4">

            <!-- Card -->
            <div class="card card-sm rounded-top-left rounded-bottom-right lift lift-lg mt-6">

              <!-- Body -->
              <div class="card-body">

                <!-- Heading -->
                <h2 class="mb-2">
                  Vision
                </h2>

                <!-- Text -->
                <p class="font-size-sm mb-4">
                  The systematic, comprehensive and clinical takedown of mental health stigma in Singapore. <br /> <br />

                  We’re building a community where mental health issues can be discussed openly, freely and actively. Together we will takedown mental health issues afflicting our working professionals aged 20 to 49 years old (the proverbial Generation XYZ). A community in unity, unity in diversity. Join our community.
                  Primary Focus: Workplace Depression & Postnatal Blues
 
                </p>
              </div>
            </div>

          </div>
          <div class="col-md-4">

            <!-- Card -->
            <div class="card card-sm rounded-top-left rounded-bottom-right lift lift-lg mt-6">

              <!-- Body -->
              <div class="card-body">

                <!-- Heading -->
                <h2 class="mb-2">
                  Mission
                </h2>

                <!-- Text -->
                <p class="font-size-sm mb-4">
                  A comprehensive, systematic, categorical takedown of mental health stigma, one face at a time, one inch at a time.
                </p>
              </div>
            </div>

          </div>
          <div class="col-md-4">

            <!-- Card -->
            <div class="card card-sm rounded-top-left rounded-bottom-right lift lift-lg mt-6">

              <!-- Body -->
              <div class="card-body">

                <!-- Heading -->
                <h2 class="mb-2">
                  Objectives
                </h2>

                <!-- Text -->
                <p class="font-size-sm mb-1">
                  ● Reduce mental health stigma.
                </p>
                <p class="font-size-sm mb-1">
                  ● Increase awareness of mental health issues.
                </p>
                <p class="font-size-sm mb-1">
                  ● Increase access to critical care.
                </p>
                <p class="font-size-sm mb-1">
                  ● Tackling workplace depression in professionals will be the team’s primary goal.
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>

    <!-- Our Core Pillars -->
    <section class="py-10 py-md-12 bg-gradient-light">
      <div class="container-lg">
        <div class="row justify-content-center">
          <div class="col-md-10 col-lg-8 text-center">
            <!-- Preheading -->
            <h2 class="display-3 mb-6">
              Our Core Pillars
            </h2>

          </div>
        </div>
        <div class="row mt-n8 mt-md-n9">
          <div class="col-md-6 col-lg-4">

            <!-- Item -->
            <div class="d-flex mt-8 mt-md-9">

              <!-- Icon -->
              <div class="icon text-primary-light">
<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"></path><path d="M12 11a4 4 0 110-8 4 4 0 010 8z" fill="#335EEA" opacity=".3"></path><path d="M3 20.2c.388-4.773 4.262-7.2 8.983-7.2 4.788 0 8.722 2.293 9.015 7.2.012.195 0 .8-.751.8H3.727c-.25 0-.747-.54-.726-.8z" fill="#335EEA"></path></g></svg>
              </div>

              <!-- Body -->
              <div class="ml-5">

                <!-- Title -->
                <p class="font-size-lg font-weight-bold mb-1">
                  Awareness
                </p>

                <!-- Text -->
                <p class="font-size-sm mb-0">
                  <ul>
                    <li>Outreach Team</li>
                    <li>Posts Sharing</li>
                    <li>Likeminded Ambassadors</li>
                  </ul>
                </p>

              </div>

            </div>

          </div>
          <div class="col-md-6 col-lg-4">

            <!-- Item -->
            <div class="d-flex mt-8 mt-md-9">

              <!-- Icon -->
              <div class="icon text-primary-light">
                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"></path><path d="M22 15.01l.025-.01v4.586a1 1 0 01-1.707.707L18.025 18H5a3 3 0 01-3-3V6a3 3 0 013-3h14a3 3 0 013 3v9.01zM6.168 10.555C7.678 12.819 9.646 14 12 14c2.354 0 4.322-1.18 5.832-3.445a1 1 0 10-1.664-1.11C15.01 11.181 13.646 12 12 12c-1.646 0-3.01-.82-4.168-2.555a1 1 0 00-1.664 1.11z" fill="#335EEA"></path></g></svg>
              </div>

              <!-- Body -->
              <div class="ml-5">

                <!-- Title -->
                <p class="font-size-lg font-weight-bold mb-1">
                  Builders
                </p>

                <!-- Text -->
                <p class="font-size-sm mb-0">
                  <ul>
                    <li>Story Curation (HumansofNY Style)</li>
                    <li>Videos of Personal Sharing and Coping Skills</li>
                    <li>Personal Positive Messages</li>
                  </ul>
                </p>

              </div>

            </div>

          </div>
          <div class="col-md-6 col-lg-4">

            <!-- Item -->
            <div class="d-flex mt-9">

              <!-- Icon -->
              <div class="icon text-primary-light">
                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"></path><path d="M5.133 2.036c6.945 3.876 9.445 8.206 7.5 12.99-4.97-.79-7.47-5.12-7.5-12.99z" fill="#335EEA" opacity=".3"></path><path d="M18.742 2.036c-6.945 3.876-9.445 8.206-7.5 12.99 4.97-.79 7.47-5.12 7.5-12.99z" fill="#335EEA" opacity=".3"></path><path d="M12 1c-4.076 6.83-4.076 11.83 0 15 3.91-3.17 3.91-8.17 0-15z" fill="#335EEA" opacity=".3"></path><path d="M6.344 13h11.312a1 1 0 01.958 1.287l-2.186 7.288A2 2 0 0114.512 23H9.488a2 2 0 01-1.916-1.425l-2.186-7.288A1 1 0 016.344 13z" fill="#335EEA"></path></g></svg>
              </div>

              <!-- Body -->
              <div class="ml-5">

                <!-- Title -->
                <p class="font-size-lg font-weight-bold mb-1">
                  Care Team
                </p>

                <!-- Text -->
                <p class="font-size-sm mb-0">
                  <ul>
                    <li>Education Arm</li>
                    <li>Workplace (Zoom) Seminars</li>
                    <li>Best Practices & Escalation Protocol Implementation</li>
                  </ul>
                </p>

              </div>

            </div>

          </div>
        </div>
      </div>
    </section>

    <!-- People, Logo, Story, Slogan -->
    <section class="pt-9 pt-md-11 pb-9 pb-md-11 bg-gradient-light">
        <div class="container-lg">
          <div class="row justify-content-center">
            <div class="col-12 col-lg-10 ">
              <!-- Card -->
              <div class="card card-lg rounded-top-left rounded-bottom-right lift lift-lg">
                <div class="row no-gutters">
                  <div class="col-md-6 bg-cover rounded-top-left my-n2" :style="{ backgroundImage: `url(${backgroundUrl})`, backgroundPosition: 'center center' }">
  
                    <!-- Image (placeholder) -->
                    <!-- <img class="img-fluid invisible" src="./home_files/banner.jpeg" alt="..."> -->
                    <img
                      class="img-fluid invisible"
                      :src="'banner.jpg' | img"
                      alt="...">
                  </div>
                  <div class="col-md-6">
                    <!-- Body -->
                    <div class="card-body">
                      <!-- Text -->
                      <p class="mb-4 text-muted">
                          Lives of SG is made up of a team of likeminded friends united by a common theme, to promote mental health awareness. We are starting this movement, as a psychological first aid, a wound band aid.  The suicide rate has risen during COVID-19, and it will likely climb when more retrenchments kick in. In line with suicide prevention month, we, a team of professionals, intend to share our stories and coping mechanisms, to hopefully help you get through these tough times.
                      </p>
    
                      <div class="row mt-6 align-items-center justify-content-center">

                          <button class="btn btn-primary btn-block rounded-sm"><router-link class="text-center text-white" to="/people" >Our People</router-link></button>
                        </div>
  
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-center mt-4">
              <p class="col-1g-8 col-10 col-md-10 text-muted text-center">
                  We are setting up this platform, a Chicken soup for the soul short story collection, SG style and an anonymous forum, for you to share and for others to care. Your story is unique, it doesn’t need to be Pulitzer prize level. It just has to be representative of you. The group provides an opportunity for its participants to connect with peers who share similar experiences and includes an emphasis on tools and skills (e.g., coping skills through safety planning) that can help us all stay safe from a future suicide attempt.
              </p>
              </div>
        </div>
    </section>
    <!-- Logo, Story, Slogan -->
    <section class="pt-9 pt-md-11 pb-9 pb-md-11 bg-gradient-light">
      <div class="container-lg">
          <div class="row justify-content-center">
            <div class="col-12 col-lg-10 ">
                <!-- Card -->
                <div class="card card-lg rounded-top-left rounded-bottom-right lift lift-lg">
                  <div class="row no-gutters">
                      <div class="col-md-4 bg-cover rounded-top-left my-n2" :style="{ backgroundImage: `url(${logoUrl})`, backgroundPosition: 'center center', height: '50%'}">
                        <!-- Image (placeholder) -->
                        <!-- <img class="img-fluid invisible" src="./home_files/logo.jpeg" alt="..."> -->
                        <img
                          class="img-fluid invisible"
                          :src="'logo.jpg' | img"
                          alt="...">

                      </div>
                      <div class="col-md-8">
                        <!-- Body -->
                        <div class="card-body">
                            <!-- Title -->
                            <h4>
                              Story of the Logo
                            </h4>
                            <!-- Text -->
                            <p class="mb-4">
                              I feel that life is about building relationships with people... SG has come a long way, from the days of kampung fishing villages... We have progressed and advanced technologically, economically and financially. Alas somehow, along with this is the lost of that sense of belonging... that sense of pride, that community feel, that kampung spirit. Hence the logo signifies... hand in hand... supporting your friend... join our community... a community in unity... unity in diversity
                            </p>
                            <!-- Footer -->
                            <div class="d-flex align-items-center">
                              <!-- Avatar -->
                              <div class="avatar avatar-xs">
                                  <img class="avatar-img rounded-circle" :src="'dr-daniel-chang.jpg' | img" alt="...">
                              </div>
                              <!-- Body -->
                              <div class="ml-4 text-left">
                                  <!-- Title -->
                                  <p class="mb-0 mb-md-0">
                                    Raising awareness for what truly matters,
                                  </p>
                                  <!-- Position -->
                                  <p class="small text-muted mt-n1 mb-0">
                                    Dr Daniel Chang
                                  </p>
                              </div>
                            </div>
                        </div>
                      </div>
                  </div>
                </div>
            </div>
          </div>
      </div>
    </section>

  </div>
</template>

<script>
import AppHeader from './../layout/Header.vue'
import backgroundUrl from '../assets/img/banner.jpg'
import logoUrl from '../assets/img/logo.jpg'

export default {
  name: 'AboutUs',

  components: {
    AppHeader
  },
  data () {
    return {
      msg: 'Welcome to Index!!!',
      backgroundUrl,
      logoUrl,
      isShownModal: false,
      inputError: false,
      checkboxState: false,

      pagination: {
        limit: 20,
        offset: 0,
        total: 60
      }
    }
  },
  mounted () {
  },
  methods: {
    showToast () {
      this.$store.commit('toast/NEW', { type: 'success', message: 'hello' })
    },
    submitModalHandler () {
      // some logic
      this.isShownModal = false
    },
    onBlur () {
      console.log('onBlur!!!')
    },
    onEnter () {
      console.log('onEnter!!!')
    },
    onEsc () {
      console.log('onEsc!!!')
    },
    onClickIcon () {
      console.log('onClickIcon!!!!')
    }
  },

  filters: {
    img: function (value) {
      return require('@/assets/img/' + value)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
