<template>
  <div>
    <AppHeader />
    <!-- CONTENT -->
    <!-- CONTENT -->
    <section class="pt-6 pt-md-11 pb-10 pb-md-12 bg-gradient-light">
      <div class="container-lg">
          <div class="row justify-content-center">
            <div class="col-md-10 col-lg-8">
                <!-- Preheading -->
                <h6 class="text-uppercase text-primary text-center mb-5">
                  Legal
                </h6>
                <!-- Heading -->
                <h1 class="display-3 text-center mb-4">
                  Privacy Policy
                </h1>
                <!-- Text -->
                <p class="font-size-lg text-center text-muted mb-0">
                  We also may automatically collect certain data when you use the Solutions, such as (1) IP address; (2) domain server; (3) type of device(s) used to access the Solutions; (4) web browser(s) used to access the Solutions; (5) referring webpage or other source through which you accessed the Solutions; (6) geolocation information; and (7) other statistics and information associated with the interaction between your browser or device and the Solutions (collectively “Traffic Data”). Depending on applicable law, some Traffic Data may be Personal Information.
                </p>
                <!-- Divider -->
                <hr class="hr-sm border-warning my-10 my-md-12">
                <!-- Heading -->
                <h2 class="mb-4 font-family-sans-serif">
                  Personal Data Protection Act
                </h2>
                <!-- Text -->
                <p class="mb-6">
                  In Singapore, Personal Data and Protection Act (PDPA) establishes a data protection law that comprises various rules governing the collection, use, disclosure and care of personal data. It recognises both the rights of individuals to protect their personal data, including rights of access and correction, and the needs of organisations to collect, use or disclose personal data for legitimate and reasonable purposes. The PDPA provides for the establishment of a national Do Not Call (DNC) Registry. The DNC Registry allows individuals to register their Singapore telephone numbers to opt out of receiving marketing phone calls, mobile text messages such as SMS or MMS, and faxes from organisations. The PDPA will ensure a baseline standard of protection for personal data across the economy by complementing sector-specific legislative and regulatory frameworks. This means that organisations will have to comply with the PDPA as well as the common law and other relevant laws that are applied to the specific industry that they belong to, when handling personal data in their possession.
                  For more information regarding PDPA, please refer to <a href="www.pdpc.gov.sg">www.pdpc.gov.sg</a>
                </p>
                <!-- Heading -->
                <h3 class="font-family-sans-serif mb-4">
                  The PDPA takes into account the following concepts:
                </h3>
                <!-- List -->
                <ul class="list-checked list-checked-circle list-checked-primary mb-9 mb-md-10">
                  <li>
                      Consent – Organisations may collect, use or disclose personal data only with the individual's knowledge and consent (with some exceptions);
                  </li>
                  <li>
                      Purpose – Organisations may collect, use or disclose personal data in an appropriate manner for the circumstances, and only if they have informed the individual of purposes for the collection, use or disclosure; and
                  </li>
                  <li>
                      Reasonableness – Organisations may collect, use or disclose personal data only for purposes that would be considered appropriate to a reasonable person in the given circumstances.
                  </li>
                </ul>
            </div>
          </div>
      </div>
    </section>
  </div>
</template>

<script>
import AppHeader from './../layout/Header.vue'

export default {
  name: 'Privacy',

  components: {
    AppHeader
  },
  data () {
    return {
      msg: 'Welcome to Index!!!',
      isShownModal: false,
      inputError: false,
      checkboxState: false,

      pagination: {
        limit: 20,
        offset: 0,
        total: 60
      }
    }
  },
  mounted () {
  },
  methods: {
    showToast () {
      this.$store.commit('toast/NEW', { type: 'success', message: 'hello' })
    },
    submitModalHandler () {
      // some logic
      this.isShownModal = false
    },
    onBlur () {
      console.log('onBlur!!!')
    },
    onEnter () {
      console.log('onEnter!!!')
    },
    onEsc () {
      console.log('onEsc!!!')
    },
    onClickIcon () {
      console.log('onClickIcon!!!!')
    }
  },

  filters: {
    img: function (value) {
      return require('@/assets/img/' + value)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
